<template>
    <div class="navigation-inner">
        <button-wrapper type="navigation" color="danger" @click="onReject">
            退回案件
        </button-wrapper>
        <div class="button-group">
            <el-input
                navigation-bar
                v-model="veevaCode"
                class="input"
                placeholder="請輸入Veeva code"
            ></el-input>
            <button-wrapper
                type="navigation"
                color="primary"
                :disabled="!veevaCode"
                @click="onDisplay"
            >
                上傳前端
            </button-wrapper>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
    data() {
        return {
            veevaCode: '',
        };
    },
    methods: {
        ...mapMutations({
            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        onReject() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要退回此案例嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Case',
                    function: 'reject',
                    successMsg: '案例已退回',
                    data: this.$route.params.id,
                };
                await this.apiClient(PAYLOAD);
                this.$router.push({ name: 'Manager_Case_List' });
            });
        },
        onDisplay() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要將此案例上傳前端嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const DATA = {
                    id: this.$route.params.id,
                    veeva: { veeva: this.veevaCode },
                };
                const PAYLOAD = {
                    category: 'Case',
                    function: 'display',
                    successMsg: '案例已上傳前端',
                    data: DATA,
                };
                await this.apiClient(PAYLOAD);
                this.$router.push({ name: 'Manager_Case_List' });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.input {
    width: 320px;
}
.button-group {
    display: flex;
    > * {
        margin-right: 20px;
    }
    :last-child {
        margin-right: 0;
    }
}
</style>
