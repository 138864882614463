<template>
    <div
        v-if="currentNavbar"
        class="navigation-bar"
        :class="{ 'dark-bg': isDarkBg }"
    >
        <div class="wrapper">
            <component :is="currentNavbar"></component>
        </div>
    </div>
</template>

<script>
import BeforeReview from '@/components/BottomNavigation/Manager/BeforeReview.vue';
import AfterReview from '@/components/BottomNavigation/Manager/AfterReview.vue';
import { mapState } from 'vuex';

export default {
    components: {
        BeforeReview,
        AfterReview,
    },
    computed: {
        ...mapState({
            state: (state) => state.manager.state,
        }),
        currentNavbar() {
            if (this.$route.name !== 'Manager_Form') return '';
            switch (this.state) {
                case 'review':
                    return 'before-review';
                case 'approve':
                    return 'after-review';
                case 'display':
                case 'reject':
                case 'draft':
                case 'doctor-save':
                case 'doctor-send':
                default:
                    return '';
            }
        },
        isDarkBg() {
            switch (this.$route.name) {
                default:
                    return true;
            }
        },
        isOnIpad() {
            return !this.$route.path.match(/manager/);
        },
    },
};
</script>

<style lang="scss" scoped>
.navigation-bar {
    bottom: 0;
}
</style>
