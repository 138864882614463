<template>
    <div class="navigation-inner">
        <div class="button-group">
            <button-wrapper
                type="navigation"
                color="normal"
                :width="320"
                @click="onReject"
            >
                未通過，回到列表
            </button-wrapper>
            <button-wrapper
                type="navigation"
                color="normal"
                :width="320"
                @click="onApprove"
            >
                確定可送審Veeva Code
            </button-wrapper>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
    methods: {
        ...mapMutations({
            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        onReject() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要退回此案例嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Case',
                    function: 'reject',
                    successMsg: '案例已退回',
                    data: this.$route.params.id,
                };
                await this.apiClient(PAYLOAD);
                this.$router.push({ name: 'Manager_Case_List' });
            });
        },
        onApprove() {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要送審此案例嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Case',
                    function: 'approve',
                    successMsg: '案例已送審',
                    data: this.$route.params.id,
                };
                await this.apiClient(PAYLOAD);
                this.$router.push({ name: 'Manager_Case_List' });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.navigation-inner {
    justify-content: flex-end !important;
}
.button-group {
    display: flex;
    > * {
        margin-right: 20px;
    }
    :last-child {
        margin-right: 0;
    }
}
</style>
